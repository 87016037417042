.sidebar {
  background-color: rgb(21, 29, 52) !important;
  font-size: 14px;
  height: 100vh;
  width: 230px;
}

.sidebar-minimized .sidebar .nav {
  width: 64px;
}

.sidebar .nav {
  width: 230px;
  flex-direction: column;
  min-height: 100vh !important;
}

.sidebar-fixed .sidebar {
  position: fixed;
  z-index: 1019;
  width: 230px;
  height: calc(100vh - 64px);
}

.sidebar-minimized.sidebar-fixed .sidebar {
  @media(min-width: 992px) {
    width: 64px;
  }
}

@media (max-width: 991.98px) {
  .sidebar {
    position: fixed;
    z-index: 1019;
    width: 230px;
    height: calc(100vh - 64px);
    margin-left: -230px;
  }
}

.sidebar-minimized .sidebar .nav-item {
  @media (min-width:992px) {
    width: 64px;
  }
}

.sidebar .nav-item .nav-link {
 line-height: 60px;
  &:hover {
    color: rgba(255, 255, 255, 0.65);
  }
}

.sidebar-minimized .nav-item .nav-link {
  position: relative;
  padding-left: 0;
  margin: 0;
  overflow: hidden;
  &:hover {
    @media (min-width: 992px) {
      background-color: #21293e ;
      color: #eeeff4 !important;
    }
    .badge {
      display: inline;
    }
  }
  &.nav-dropdown-toggle::before {
    display: none;
  }
}

.nav-link {
  i {
    display: block;
    float: left;
    font-size: 18px;
  }
  .badge {
    position: absolute;
    right: 15px;
    display: none;
  }

  &:hover {
    @media (min-width: 992px) {
      background-color: #21293e ;
      color: #eeeff4 !important;
    }
    .badge {
      display: inline;
    }
  }
  &.nav-dropdown-toggle::before {
    display: none;
  }
}

.nav-link {
  .sidebar-fixed {
    color: rgba(255, 255, 255, 0.65);
    padding: 5px 0 !important;
  }
}

.icona-nav {
  font-size: 24px !important;
  font-weight: 900px !important;
  margin: 10px 10px;
  width: 40px !important;
  vertical-align: middle;
}

.sidebar .nav-link, .sidebar .navbar .dropdown-toggle, .navbar .sidebar .dropdown-toggle {
  color: rgba(255, 255, 255, 0.65);
  padding: 5px 0 !important;
}

.sidebar .nav-item {
  position: relative;
  margin: 0;
  width: 230px;
  transition: background .3s ease-in-out;
  &:hover {

    background-color: rgba(90,104,114,.48)!important;
    color: #eeeff4!important;
  }
}

.sidebar .nav-link {
  position: relative;
  padding-left: 0;
  margin: 0;
  white-space: nowrap;
  &.active {

    background: linear-gradient(30deg,#0090d1,#00c3ea)!important;
    color: #fff!important;

  }
}
.sidebar .nav-link.active:hover {
  background: #00c3ea!important;
  color: #fff!important;
}

@media (max-width: 991.98px) {
.sidebar-mobile-show .sidebar {
    width: 230px;
    margin-left: 0;
  }
}

.sidebar-mobile-show {
  .sidebar {
    width: 230px;
    margin-left: 0;
  }

  .main {
    margin-right: - 230px !important;
    margin-left: 230px !important;
  }
}

.menuTooltip {
  .bs-tooltip-auto[x-placement^=right] .arrow::before, .bs-tooltip-right .arrow::before {
    border-right-color: #005075!important;
  }

  .tooltip-inner {
    border-radius: 0;
    background:#005075;
    opacity: 1;
  }
}
