.app-header.navbar {
  display: flex;
  border-color: #415ca3 !important;
  background-color: rgb(21, 41, 53) !important;
  height:64px;
}

.header-fixed .app-body {
  margin-bottom: 50px; }
  @media (max-width: 768px) {
}

.app-header * a { color: #415ca3; }
.app-header * a:hover { text-decoration: none; color:rgb(0, 153, 255); }

.brand-minimized .app-header.navbar .navbar-brand {
  font-size: 25px;
  height: 55px;
  line-height: 52px;
  margin-left: 10px !important;
  @media(max-width: 736px) {
    margin-left: 0 !important;
    height: 55px;
    width: 80px;
    line-height: initial;
  }
}

.app-header.navbar .navbar-toggler {
  width:55px;
  font-size: 21px;
  padding: 1px 0px;
  &:focus {
    box-shadow: 0 0 0 0;
    outline: 0;
  }
}

.app-header.navbar .navbar-brand {
  text-align: left;
  font-size: 24px;
  flex:2;
  padding: 15px 0 0 0;
  font-size: 24px;
  vertical-align: middle;
  line-height: 10px;
  color:#fff;
  margin: 0px !important;
  margin-left: 15px;
  padding: 0px !important;
  height: 20px;

  @media (max-width: 568px) {
    padding: 0;
    margin-top: 1px;
    line-height: 28px;
    margin-left: -37%;
    font-size: 22px;
    max-height: 50px;
  }

  @media (max-width: 667px) {
    padding: 2px;
    line-height: 30px;
    margin-left: -37%;
  }

  @media (min-width: 992px) {
    width: 50px;
    background-color: #415ca3;
    background-image: none;
    background-size: 24px;
    display: flex;
  }

  @media (max-width: 991.98px){
    position: relative !important;
    left: 0 !important;
    margin-left: 0px !important;
  }
}

.navbar {
  padding: 0 !important;
}

.app-header.navbar .navbar-brand {
  text-align: left;
  font-size: 24px;
  flex:2;
  padding: 15px 0 0 0;
  vertical-align: middle;
  line-height: 10px;
  color:#fff;
  border: 1px solid rgb(21, 41, 53);
  margin: 0px !important;
  margin-left: 15px;
  padding: 0px !important;
  height: 20px;
  background-color: rgb(21, 41, 53) !important;

  @media (max-width: 568px) {
    padding: 0;
    margin-top: 1px;
    line-height: 28px;
    margin-left: -37%;
    font-size: 22px;
    max-height: 50px;
  }

  @media (max-width: 667px) {
    padding: 2px;
    line-height: 30px;
    margin-left: -37%;
  }

  @media (min-width: 992px) {
    width: 50px;
    background-color: #rgb(21, 41, 53) !important;
    background-image: none;
    background-size: 24px;
    display: flex;
  }

  @media (max-width: 991.98px){
    position: relative !important;
    left: 0 !important;
    margin-left: 0px !important;
  }
}

.app-header.navbar .nav-item .nav-link > .img-avatar, .app-header.navbar .nav-item .dropdown-toggle > .img-avatar { height: 20px; }
.app-header.navbar .nav-item .nav-link:hover { color:rgb(0, 153, 255); }
.app-header.navbar .nav-item .nav-link:active { color:rgb(0, 153, 255); }

.app-header.navbar {
  border: 0 !important;
  font-size: 24px;
}

.header-logged-name {
  @media(min-width:660px) {
    padding-right: 20px;
    display: inline!important;
  }
}

.app-header.navbar .nav-item .nav-link:hover, .app-header.navbar .nav-item .dropdown-toggle:hover {
  color: white;
}

.app-header.navbar .nav-item .nav-link:active, .app-header.navbar .nav-item .dropdown-toggle:active {
  color: white;
}

.head-div-img {
  height: 64px;
  line-height: 58px;
}

.head-img {
  height: 24px;
  @media(max-width: 736px) {
    height: 24px;
    vertical-align: 1px;
  }
}

.user-div {
  padding: 0 20px;
  color: #fff;
  font-size: 15px;
}

.app-header.navbar .nav-item {
  position: relative;
  min-width: 50px;
  text-align: center;
}

.header-dropdown-user {
  svg {
    font-size: 24px;
  }
  font-size: 15px;
  background-color: inherit;
  .nav-link {
     line-height: unset !important;
     padding: 10px 10px !important;
  }
  .nav-link:hover {
    background-color: inherit !important;
    width: unset !important;
  }
  &.show {
      & button {
          display: block;
      }
  }
}

.ml-auto .navbar-nav {
  margin-left: auto !important;
  line-height: 50px;
  cursor: pointer;
  padding: 3px 20px 0px;
  a {
    svg {
      width: 25px;
    }
  }
}

.dropdown-menu {
  &.show {
    position: absolute;
    will-change: transform;
    top: 0px;
    left: 0px;
    transform: translate3d(-1px, 40px, 0px);
    font-size: 14px;
    .dropdown-item {
      box-shadow: none!important;
      outline: none!important;
      svg {
        font-size: 14px;
      }
    }
    .dropdown-item:not(:last-child) {
      content: "";
      margin: 0 auto;
      width: 95%;
      display: block;
      border-bottom: 1px solid #c2cfd6;
    }
  }
}

.app-header.navbar .dropdown-item {
    min-width: 180px;
    color:rgba(0,0,0,.65);
}

.app-header.navbar .dropdown-item:active {
  border: 1px solid#435cd0;
  color: #4e79f3;
  background-color: #fff;
}

.dropdown-item:active {
  border: 1px solid#435cd0;
  color: #4e79f3;
  background-color: #fff;
}

.app-header.navbar .nav-item .nav-link:hover, .app-header.navbar .nav-item .dropdown-toggle:hover {
  color: white;
}

.app-header.navbar .nav-item .nav-link:active, .app-header.navbar .nav-item .dropdown-toggle:active {
  color: white;
}

.user-text {
  float:left;
  margin: 8px 0 0 0;
}

.btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active, .show > .btn-secondary.dropdown-toggle {
  background-color: inherit;
}

.p-fisso {
  margin: 0px
}

.p-variabile {
  margin: 0px
}

.item-azienda {
  font-size: 15px;
  color: #fff;
}

.app-header.navbar .navbar-nav {
    flex-direction: row;
    align-items: center;
}

@media (max-width: 991.98px) {
  .d-md-down-none {
    display: none!important;
  }
}

.lista-aziende-drop {
  & button {
    background-color: inherit;
    border: none !important;
    &:hover {
        background-color: inherit !important;
        border: none !important;
    }
    &:focus {
        background-color: inherit !important;
        border: none !important;
        box-shadow: none !important;
    }
    &:active {
        background-color: inherit !important;
        border: none !important;
        box-shadow: none !important;
        color: #fff;
    }
  }
  &.show {
    font-size: 14px;
        .dropdown-item {
      box-shadow: none!important;
      outline: none!important;
      svg {
        font-size: 14px;
      }
      .pinCompany {
           opacity: 0.5;
          &.defaultCompany {
             opacity: 1;
          }
      }
    }
    .dropdown-item {
        display: flex;
        &:not(:last-child) {
            content: "";
            margin: 0 auto;
            width: 100%;
            display: flex;
            border-bottom: 1px solid #c2cfd6 !important;
        }
    }
    .dropdown-menu {
      max-height: 350px;
      overflow: auto;
    }
  }
}
