.dash-div {
  min-height: 320px;
  border-radius: 5px;

  @media(max-width:768px) {
    margin-bottom: 40px;
    font-size: 10px;
    min-height: 150px;
  }

  .img-box {
    margin-bottom: 0px;
    font-size: 30px;
  }

  .box-title {
    font-size: 20px;
    font-weight: 700px;
  }

  .box-bottom {
    position:absolute;
    bottom:20px;
    margin-left:-25%;
    right:25%;
    left:50%;
    @media(max-width:768px) {
      margin-bottom:40px;
    }
  }
  .text-box {
    font-size: 16px;
    margin: 4% 0;
    padding: 0 20px;
  }
}

.alert-dashboard {
  button.close {
    height:100%;
    line-height: 0;
    font-size: 22px;
    color:rgb(21, 41, 53);
  }
  border: 1px solid rgb(0, 80, 117)!important;
  border-radius: 0;
  background-color: rgb(255, 255, 255);
  color: rgba(0, 0, 0, 0.65);
  width: 100%;
  border: none;
  min-height: 70px;
  align-items: center;
      line-height: 46px;
 .close {
    font-size: 25px;
    @media (max-width: 1290px) {
      top: 0 !important;
    }
  }
}

.btn-ambiti {
  border-radius: 4px;
  width: 100px;
  background-color: #415ca3;
  border: 1px solid #415ca3;
  padding: 8px;
  font-size: 14px;
  transition: opacity .15s ease-in-out;
  &:hover {
    opacity: 0.8;
    background-color: #415ca3;
    border: 1px solid #415ca3;
  }
  &:active {
    background-color: #415ca3 !important;
    border: 1px solid #415ca3 !important;
  }
  &:focus {
    box-shadow: 0 0 0 0 !important;
  }
}

.alert-buttons {
  text-align: right;
  @media screen and (min-device-width : 500px) and  (max-device-width : 1300px) {
    right:inherit;
    top: 65%
  }
  @media (max-width: 500px) {
    position: initial;
    display: flex;
  }
}


.dashboard-card {
  height: 152px;
  margin-bottom: 24px;
  .card-right {
    float: right;
    background: #f2f5f8;
    height: 152px;
    width: 51px;
    display: flex;
    align-items: flex-end;
    text-align: center;
  }
  .card-right a {
    padding: 10px;
    margin: 0 auto;
    border-top: 1px solid #c9d9e8;
  }
}
.user-div {
  .user-profile-panel {
    display: none;
    position: absolute;
    right: 0;

    color: #000;
    width: 420px;
    min-height: 200px;
    background:#fff;
    box-shadow: 0px -2px 8px 0px rgba(21, 41, 53, 0.24);
    margin-top:10px;
    .qr-container {
      margin: 0px 50px;
    }
    a {
      color: #000;
      cursor: pointer;
    }
    .link {
      cursor: pointer;
    }
    .user-profile-section {
      margin: 0 24px;
      padding-top: 20px;
      border-bottom: 1px solid #c9d9e8;
      p {
        margin: 0;
        padding: 0;
      }
    }
  }
  .show {
    display: block;
  }


}
.language-popover {
  border-radius: 0;
  padding: 0;
  width: 240px;
  .popover-body {
    padding: 0!important;
  }
  .list-group-item {
    padding: 8px 16px;
    border:none;
    a {
      cursor: pointer;
      color: #0099ff;
    }
    &.active {
      background: rgb(222, 240, 247);
      color: rgb(0, 144, 209);
    }
  }
}
