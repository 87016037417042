.card-download {
  width: 100%;
  border-width: 0px;
  display: inline-flex;
  /* height: 160px; */

  .body {
    display: inline-flex;
    background-color: rgba(254, 254, 255, 0.5);
    .div-logo {
      padding: 0px 20px;
      line-height: 150px;
      min-width: 250px;
    }
    .div-description {
      padding: 30px;
      width: 100%;
      align-items: center;
      justify-content: center;
      a {
        cursor: pointer;
        text-decoration: underline;
      }
      .span-os {
        font-weight: bold;
        margin-right: 20px;
      }
      .span-versione {
        margin: 10px;
      }
    }
  }
  .div-download {
    text-align: center;
    background-color: rgb(242, 245, 248);
    padding: 50px;
    min-width: 300px;

    button {
      width: 200px;
    }
  }
  .div-download-under-maintenance {
    text-align: center;
    background-color: rgb(242, 245, 248);
    padding-top: 20px;
    min-width: 300px;
    max-width: 300px;

    button {
      width: 200px;
    }
  }
}

.qlik-history-link {
  padding: 0px 0px 0px 20px;
  a {
    cursor: pointer;
    text-decoration: underline;
  }
}

.qlik-history {
  padding: 20px;
  h4 {
    margin-bottom: 20px;
  }
  a {
    cursor: pointer;
    text-decoration: underline;
  }
  .span-os {
    font-weight: bold;
    margin-right: 20px;
  }
  .span-versione {
    margin: 10px;
  }
}
