.breadcrumb {
    height: 56px;
    padding-left: 40px;
    color: #005075!important;
    background-color: #fff!important;
    font-family: Cairo-SemiBold;
    font-size: 20px;
    line-height: 28px;
    border-bottom: 2px solid #0090d1;
    position: fixed;
    width: 100%;
    z-index: 1000;
    margin: 0;
    top: 64px;
}
  @media (max-width: 768px) {
    .breadcrumb {
      padding: 1rem 12px !important;
      margin-bottom: 0;
    }
  }

  .active.breadcrumb-item {
    width: 100%;
    text-align: center;
    line-height: 100%;
  }


.breadcrumb-item svg {
  margin: 0px;
  font-size: 27px;
  vertical-align: middle;
  margin-right: 1rem;
}
